import React from "react";
import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import './piechart.css'
import DonutChart from 'react-donut-chart';
class ChartsPage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
           
          }
    }


render() {

    var  dataDoughnut={
        labels: ["Principal Amount", "Total Interest"],
        datasets: [
          {
            data: [this.props.principal,this.props.total_interest],
            backgroundColor: ["#4076da", "#e83196"],
          
            
          }
        ]
      }
  
    return (
    <MDBContainer>
    
      <Doughnut data={dataDoughnut} options={{ responsive: true }} />
    </MDBContainer>
    );
  }
}

export default ChartsPage;