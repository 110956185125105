
import React, { Component } from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {MDBBtn} from 'mdbreact'
import ChartsPage from './piechart';
import './App.css';




var emi = 0,total_interest=0

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      principal:100000,
      interest:8.25,
      year:180,
       totalInterest:0,
      select_year:false,
      select_month:true,
      emi:0
    } 
  }

componentDidMount(){

  this.emiCaldidm()

}
  
  IncrementItem = () => {
    this.setState(prevState => {
      if(prevState.principal < 100000000000) {
        return {
          principal: prevState.principal +10
        }
      } else {
        return null;
      }
    },()=> this.emiCaldidm());
}
decrementItem = () => {
  this.setState(prevState => {
    if(prevState.principal < 100000000000) {
      return {
        principal: prevState.principal -10
      }
    } else {
      return null;
    }
  },()=> this.emiCaldidm());
}

IncrementItem1 = () => {
  this.setState(prevState => {
    if(prevState.interest < 100000000000) {
      return {
        interest: prevState.interest +5
      }
    } else {
      return null;
    }
  },()=> this.emiCaldidm());
}
decrementItem1 = () => {
this.setState(prevState => {
  if(prevState.interest < 1000000000000) {
    return {
      interest: prevState.interest -5
    }
  } else {
    return null;
  }
},()=> this.emiCaldidm());
}

IncrementItem2 = () => {
  if(this.state.select_month){
    this.setState(prevState => {
      if(prevState.year < 100000000000) {
        return {
          year: prevState.year +5
        }
      } else {
        return null;
      }
    },()=> this.emiCaldidm());
  }
else{
  this.setState(prevState => {
    if(prevState.year < 100000000000) {
      return {
        year: prevState.year +1
      }
    } else {
      return null;
    }
  },()=> this.emiCaldidm());
}
}
decrementItem2 = () => {
  if(this.state.select_month){
    this.setState(prevState => {
      if(prevState.year < 100000000000) {
        return {
          year: prevState.year -5
        }
      } else {
        return null;
      }
    },()=> this.emiCaldidm());
  }
else{
  this.setState(prevState => {
    if(prevState.year < 100000000000) {
      return {
        year: prevState.year -1
      }
    } else {
      return null;
    }
  },()=> this.emiCaldidm());
}
}


handleRadio(data) {
if(data==1){
  this.setState({
    select_year:true,
    select_month:false
  },()=> this.emiCaldidm())
}
else if(data==0){
  this.setState({
    select_year:false,
    select_month:true
  },()=> this.emiCaldidm())
}


};
getTotalInt = () =>{
if(this.state.select_month){
  total_interest = (((this.state.year)*emi)-this.state.principal)
}
else{
    total_interest = (((this.state.year*12)*emi)-this.state.principal)
}
this.setState({
  totalInterest:total_interest
},()=> this.emiCaldidm())
window.document.getElementById('header1').scrollIntoView('header1')

}




emiCal= () => {
  let p = this.state.principal;
  var i = this.state.interest/12/100;
  
 
  if(this.state.select_month){
    var t = this.state.year;
  }

  else if(this.state.select_year){
    var t = this.state.year*12;
  }


  emi=  p * i *(Math.pow((1 + i), t))/[(Math.pow((1 + i), t))-1];
  this.setState({
    emi:emi
  },()=>this.getTotalInt())


  
};
emiCaldidm= () => {
  
  let p = this.state.principal;
  var i = this.state.interest/12/100;
  
 
  if(this.state.select_month){
    var t = this.state.year;
  }

  else if(this.state.select_year){
    var t = this.state.year*12;
  }


  emi=  p * i *(Math.pow((1 + i), t))/[(Math.pow((1 + i), t))-1];

  this.setState({
    emi:emi
  },()=>this.getTotalIntdidm())


  
};

getTotalIntdidm = () =>{
 

  if(this.state.select_month){
    total_interest = (((this.state.year)*emi)-this.state.principal)
  }
  else{
      total_interest = (((this.state.year*12)*emi)-this.state.principal)
  }
  this.setState({
    totalInterest:total_interest
  })

  }

    handleYear(text){
      Number(text.substr(1, 5))
     if(text.length<6){
      this.setState({year:parseInt(text)},()=> this.emiCaldidm())
     }
      
    }
  render(){

 
  return (
    <div className="App" >
      <div  className="Appheader" >
     <h2 id='emitop'>EMI Calculator </h2> 
      </div>
      
          <div className="Appmain" >
            <div className="Appmain1">
      <div className="Appcontainer">

        <div className='la'>
        <div className="LoanAmount"><h1>Loan&nbsp;&nbsp;Amount</h1>
        <div className='inputtypetext'>
        <div className="input-group">
          <p style={{marginBottom:0,paddingRight:5}}><span>₹</span></p>
        <input  type="number" value={this.state.principal} onChange={(text)=>this.setState({principal:parseInt(text.target.value)},()=>this.emiCaldidm())} className="form-control " placeholder="₹" aria-label="Recipient's username with two button addons"
    aria-describedby="button-addon4">

</input>
        
 </div>
           </div>
       
        </div>

  <div className="slider1"> 
  <div class="slidecontainer">
 

  <input type="range" step={100000} min="0"   max="100000000" value={this.state.principal}    onChange={(text)=>this.setState({principal:parseInt(text.target.value)},()=>this.emiCaldidm())} className="slider" id="myRange" />
</div>


  </div>
  </div>

  <div className='la1'>

        <div className="InterestRate"> <h1>Interest&nbsp;&nbsp;Rate</h1>
        <div className='inputtypetext1'>
        <div className="input-group i1">
        <input type="number"   value={this.state.interest} onChange={(text)=>this.setState({interest:parseFloat(text.target.value)},()=>this.emiCaldidm())}  className="form-control " className="form-control" placeholder="%" aria-label="Recipient's username with two button addons"
    aria-describedby="button-addon4">

</input><p style={{marginBottom:0,paddingLeft:5}}><span>%</span></p>
        
 </div>
           </div>
        </div>
        
        <div className="slider1 "> 
        
        <div class="slidecontainer">
  
        <input type="range" step={0.01} min="1"   max="20" value={this.state.interest}    onChange={(text)=>this.setState({interest:parseFloat(text.target.value)},()=>this.emiCaldidm())} className="slider" id="myRange" />
  
</div>
        
        </div>
        </div>

          <div className='la2'>
        <div className="LoanTerm"> 
        <div className='xa'>
        <div className='xa1'>
        <h1>Loan&nbsp;&nbsp;Term </h1> </div>
        <div className='rad'>
     <div className='rad0'>  <input checked={this.state.select_month} onChange={()=>this.handleRadio(0)} class="rx" type="radio"  name="loan" value="month"/> <h2>Month</h2>  
     </div>
   
     <div className='rad1'> <input checked={this.state.select_year} onChange={()=>this.handleRadio(1)} class="rx1" type="radio" name="loan" value="year"/> <h2>Year</h2>  
  </div>
  </div>
  </div>

        <div className='inputtypetext2'>
        <div className="input-group i2">
        <input type="number"   value={this.state.year} onChange={(text)=>this.handleYear(text.target.value)} className="form-control " className="form-control " placeholder="m/years" aria-label="Recipient's username with two button addons"
    aria-describedby="button-addon4">

</input>
     
 </div>
           </div>
        </div>
        
        <div className="slider1 "> 
        <div class="slidecontainer">
  
        <input type="range" step={1} min="1" value={this.state.year} max={this.state.select_month ? "360" : "30"}   onChange={(text)=>this.setState({year:parseInt(text.target.value)},()=>this.emiCaldidm())}  className="slider" id="myRange" />

</div>
    
 
        </div>
       
        </div>
     
      </div>
     

      </div>
 <div id='header1' className="abc">
<div className="abc1" style={{paddingLeft:'20%'}}><span><h1>Break-up of Total Payment:</h1></span></div>
<div className='p1'>
  
  <div className="piechart"><ChartsPage principal={this.state.principal} total_interest={this.state.totalInterest} year={this.state.year} /></div>
  
  <div  className='pp1'>
  <div  className='pp12'>

  <div className='p2'> <h1>Principal</h1>
 <h2> ₹{this.state.principal.toFixed(2)}  </h2>  </div>
 
 
 <div className='p3'>  <h1>Total Interest</h1>

  <h2> ₹{this.state.totalInterest.toFixed(2)}  </h2>  </div> 

  </div>

  <div  className='p4'>
  <h1>Total Payment<br/>
<span>(Principal + Interest)</span></h1>
  <h2> ₹{(this.state.principal+this.state.totalInterest).toFixed(2)}  </h2>

  </div>
    </div>
  
  </div>
 
  <div className='line' > <hr/> </div>
  <div  className='pp1mobile'>
  <div className='p2'> <h1>Principal</h1>
 <h2 style={{fontSize:12}}> ₹{this.state.principal.toFixed(2)}  </h2>  </div>
 
 
 <div className='p3'>  <h1>Total Interest</h1>

  <h2 style={{fontSize:12}}> ₹{this.state.totalInterest.toFixed(2)}  </h2>  </div> 

  <div className='p4'>
  <h1>Total Payment<br/>
<span>(Principal + Interest)</span></h1>
  <h2 style={{fontSize:12}}> ₹{(this.state.principal+this.state.totalInterest).toFixed(2)}  </h2>

  </div>
    </div>
 
  <div className="total">

 
    <h1>Your Monthly Payment</h1>

    <h2>₹{this.state.emi.toFixed(2)}</h2>


  </div>

 </div>

      </div>
      
   <div className='footer'>

     <div className='footer1'> <img src={require('./images/p.jpg')} /> <h1>This EMI calculator is brought to you by Loans and Mortgage
expert Vineet Kumar Jain, Managing Partner, DKR Corporate
Finance Solutions LLP</h1></div>
     <div className='footer2'><h1>For best Loan deals,feel free to
reach Vineet at:<br/>
<span>+91 98994-00868</span><br/>  or write to him at: <br/>  <span>vkj@vineetkumarjain.com</span>
</h1></div>

   </div>
     

    </div>
     
  );
}
}

export default App;
